import React, {useEffect, useState, CSSProperties} from 'react'
import Navbar from '../Navbar/navbar'
import Footer from '../footer/Footer'
// import { User } from 'firebase/auth'
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth, db, firestore} from '../../utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';
import "./enroll.css"
import Signup from '../auth/signup';
import {useLevelSelection} from "./LevelSelectionContext"
import { useAvlUser, useEnrollment, useFetchData, useLoading, useUser } from '../../utils/FirestoreContext';
import { onAuthStateChanged, updateCurrentUser } from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';
// import { css } from '@emotion/react';
import ClipLoader from "react-spinners/ClipLoader";
import BasicCourse from '../courses/BasicCourse';
import Alert from '@mui/material/Alert';

// import { Firestore } from 'firebase/firestore';



const override = {
  display: "block",
  margin: "300px auto",
  borderColor: "#8B03FB",
};


export default function EnrollBasic( reRoutToDashboard, setreRoutToDashboard, props) {
    const [authUser] = useAuthState(auth)
    const [user, enrollUser] = useUser()
    const [selectedLevel, setSelectedLevel] = useLevelSelection()
    const [enrollmentError, setEnrollmentError] = useState("")
    const [avlUser, setAvlUser] = useAvlUser()
    const [loading, setLoading] = useLoading();
    const fetchUserData = useFetchData()
    const [userData, storedUserData] = useState()
    const [isSuccess, setIsSuccess] = useState(false)
    // const [fetchUserData] = useFirestore()
    // const [authenticated, setAuthenticated] = useState(false)


    const navigate = useNavigate()


    // if (!user){
    //   return null;
    // }
  useEffect(()=>{
    setSelectedLevel("Basic");
    // setAvlUser(avlUser)
    // console.log(avlUser)
  }, [selectedLevel])
    
    // setUserIsEnrolled(true)
    
    const handleEnrollment = async () => {
      if(!selectedLevel) {
        setEnrollmentError("please select a level")
        return;
      };
      await enrollUser(db).then((() => {
        fetchUserData()
        console.log(localStorage.getItem('userData'))
        // localStorage.setItem('userData', JSON.stringify(avlUser))
        console.log(avlUser)
        // setAvlUser(avlUser)
      })).then(()=>{
        setIsSuccess(true)
      })
    };


    useEffect(() => {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        console.log(storedUserData);
        try {
          setAvlUser(JSON.parse(storedUserData));
          console.log(avlUser);
        } catch (error) {
          console.error("Error parsing stored user data:", error);
          // Handle the error, perhaps remove the invalid data from localStorage
          localStorage.removeItem('userData');
        }
      } else {
        fetchUserData(); // Fetch the data if not found in localStorage
      }
    },[])


    // useEffect(() =>{
    //   if (avlUser) {
    //     navigate("/profile")
    //   }
    // }, [handleEnrollment])




    return (
      <div>
        {loading? (<ClipLoader size={150} color={"#8B03FB"} cssOverride={override} loading={loading} />) : (
        <>
            <Navbar />
              <>
                <div className="enroll-basic">
                  <h2 className="enroll-basic-header">Basic Level Enrollment</h2>
                </div>
                {isSuccess && (<Alert severity="success">Successfully Enrolled to the basic level<br/> Head to your profile to continue.</Alert>)}
                <BasicCourse/>
              </>
            {!authUser ? (
              <Signup reRoutToDashboard={false}  setreRoutToDashboard={false}/>
            ) : (
              <div className={loading ? "none" : "enroll-button-wrapper"}>
                {avlUser ? "" :(<button onClick={() => { handleEnrollment(); }}
                 className="enroll-basic-btn"
                >
                  Enroll Basic
                </button>)}
              </div>
            )}
          <Footer />  
        </>
        )}
      </div>
    );    
}
