import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom'
import Home from "./pages/home/home-page"
import './App.css'
import About from "./pages/about/about";
import "./pages/about/components/core-values-card";
import Login from "./pages/auth/login.jsx"
import Signup from "./pages/auth/signup";
import Profile from "./pages/profile/profile";
import EnrollBasic from "./pages/enroll/EnrollBasic";
import EnrollAdvanced from "./pages/enroll/EnrollAdvanced";
import EnrollIntermediate from "./pages/enroll/EnrollIntermediate";
import ChooseCoursePath from "./pages/courses/ChooseCoursePath";
import LevelSelectionProvider from "./pages/enroll/LevelSelectionContext"
import FirestoreProvider from "./utils/FirestoreContext";
import BasicCurriculum from "./pages/Curriculum/Basic-Curriculum";
import Mentors from "./pages/mentors/Mentors";
import IntermediateCurriculum from "./pages/Curriculum/Intermediate-Curriculum";
import AdvanceCurriculum from "./pages/Curriculum/Advance-Curriculum.jsx";
import MakePost from "./pages/blog/PostBlog.jsx";


export default function App (){
  return(
    <LevelSelectionProvider>
    <FirestoreProvider>
    <Routes>
        <Route path="/" element={<div className="home-and-about"><Home/>
        <About/>
        </div>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/enroll-basic" element={<EnrollBasic/>}/>
        <Route path="/enroll-intermediate" element={<EnrollIntermediate/>}/>
        <Route path="/enroll-advanced" element={<EnrollAdvanced/>}/>
        <Route path="/course" element={<ChooseCoursePath/>}/>
        <Route path="/profile/basic-curriculum" element={<BasicCurriculum/>}/>
        <Route path="/profile/intermediate-curriculum" element={<IntermediateCurriculum/>}/>
        <Route path="/profile/advance-curriculum" element={<AdvanceCurriculum/>}/>
        <Route path="/mentors" element={<Mentors/>}/>
        <Route path="/make-post" element={<MakePost/>}/>
        <Route path="*" element={<Navigate to={"/"}/>} />
    </Routes>
    </FirestoreProvider>
    </LevelSelectionProvider>
  )
}

